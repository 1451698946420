<template>
  <div>
    <div
      v-show="!noQuotas"
      class="quotas-page">
      <div class="quotas-list__wrapper cy-scrollbars flex-grow-1 pa-1 mr-6">
        <CyQuotasListTable/>
      </div>
      <CyQuotasOverview/>
    </div>
    <div
      v-show="noQuotas"
      class="quotas-page--empty">
      <div>
        <h3 class="mb-2">
          {{ $t('noQuotas.title') }}
        </h3>
        <p class="mb-8">
          {{ $t('noQuotas.text') }}
          <a
            :href="$docLinks.quotas.index"
            class="cy-link"
            target="_blank">
            {{ $t('noQuotas.docLink') }}
          </a>
        </p>
        <CyButton
          v-has-rights-to="'CreateQuota'"
          class="create-quota__btn"
          icon="add"
          theme="secondary"
          variant="primary"
          :to="{ name: 'newQuota' }">
          {{ $t('quotas.createQuota') }}
        </CyButton>
      </div>
    </div>
  </div>
</template>

<script>
import CyQuotasListTable from '@/components/quotas/list-table'
import CyQuotasOverview from '@/components/quotas/overview'
import { constructBreadcrumb } from '@/utils/helpers'
import { mapState } from 'vuex'

export default {
  name: 'CyPageQuotas',
  components: {
    CyQuotasListTable,
    CyQuotasOverview,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.quotas'), [
      {
        label: this.$t('routes.resourcesSection'),
        name: 'resourcesSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.resourcesSection'),
      description: {
        text: this.$t('routes.resourcesSectionDescription'),
      },
    }
  },
  computed: {
    ...mapState('organization', {
      fetchInProgress: (state) => state.fetchInProgress.quotas,
      quotas: (state) => state.available.quotas,
    }),
    ...mapState('layout', {
      tableFilters: (state) => state.dataTables?.quotas?.filters || [],
    }),
    noQuotas () {
      return _.isEmpty(this.quotas) && !this.fetchInProgress && _.isEmpty(this.tableFilters)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:Quotas',
        noQuotas: {
          title: `It looks like you don't have any quota yet`,
          text: 'Use quotas to control how much Memory, Storage and CPU a team can use.',
          docLink: 'Learn about quotas.',
        },
      },
      es: {
        title: '@:Quotas',
        noQuotas: {
          title: 'Parece que todavía no tienes ninguna quota',
          text: 'Use quotas para controlar cuánta memoria, almacenamiento y CPU puede usar un equipo.',
          docLink: 'Más información sobre las quotas.',
        },
      },
      fr: {
        title: '@:Quotas',
        noQuotas: {
          title: `Il semblerait que vous n'ayez pas encore de quotas`,
          text: 'Utilisez les quotas pour controler combien de mémoire, de stockage et de CPU une équipe peut utiliser.',
          docLink: 'Apprenez-en plus sur les quotas.',
        },
      },
    },
  },
}
</script>

<style lang="scss"> /* FIXME: scope!! */
.quotas-page {
  display: flex;
  max-height: 100%;

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;
      text-align: center;

      h3 {
        color: get-color("primary", "light-2");
      }

      p {
        color: get-color("primary", "light-3");
      }
    }
  }

  .quotas-list__wrapper {
    overflow-y: auto;
  }
}
</style>
