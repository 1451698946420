<template>
  <div class="resource-utilisation-card">
    <div class="resource-title mb-2">
      {{ $t(`quotas.${resource.type}`) }}
    </div>
    <div class="d-flex justify-space-between mb-1">
      <div class="percentage">
        {{ getUsagePercentage(resource) }}%
      </div>
      <div class="used-of">
        {{ getUsedOfText(resource) }}
      </div>
    </div>
    <v-progress-linear
      :value="getUsagePercentage(resource)"
      :color="getUsageBarColor(resource)"
      background-color="grey lighten-2"
      height="8"
      rounded/>

    <!-- TODO: FE#6599 apply filter by resource on quota overview -->
    <!-- <div
      class="detail-link cy-link mt-2"
      @click="$emit('viewDetails', resource.type)">
      {{ $t('viewQuotas', { type: $t(`quotas.${resource.type}`) }) }}
    </div> -->
  </div>
</template>

<script>
import { getUsedOfText, getUsagePercentage, getUsageBarColor } from '@/utils/helpers/quotas'

export default {
  name: 'CyQuotasResourceUtilisationCard',
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUsedOfText,
    getUsagePercentage,
    getUsageBarColor,
  },
  i18n: {
    messages: {
      en: {
        viewQuotas: 'View {type} quotas',
      },
      es: {
        viewQuotas: 'Ver cuotas de {type}',
      },
      fr: {
        viewQuotas: 'Voir quotas de {type}',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-utilisation-card {
  width: 355px;

  .resource-title {
    color: get-color("grey", "dark-2");
    font-size: map.get($font-sizes, "sm");
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .percentage {
    color: get-color("primary", "dark-1");
    font-weight: $font-weight-bold;
  }

  .detail-link {
    &:hover {
      color: get-color("secondary");
    }
  }
}
</style>
